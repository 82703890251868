import { Box, Button, Card, Divider, ListItem, Typography, useTheme } from '@mui/material';
import React from 'react';
import { StyledCard } from './InfoCard';

const SideCard = ({ title, subTitle, items, matches }) => {
  console.log('');
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection={'column'} justifyContent={'start'} sx={{width: '100%'}} >
      <Typography gutterBottom variant={matches ? 'h4' : 'h5'}>{title}</Typography>
      <StyledCard sx={{ width: '100%' }}>
        <Box display="flex" flexDirection={'column'} justifyContent={'start'} alignItems={'start'} mb={1}>
          <Typography color={'lightgray'} variant={matches ? 'body1' : 'body2'}>
            {subTitle}
          </Typography>
          {items?.map((item) => (
            <ListItem>{item}</ListItem>
          ))}
        </Box>
      </StyledCard>
    </Box>
  );
};

export default SideCard;
